import MyCarousel from "../components/MyCarousel";

const Home = () => {
  return (
    <div>
      <MyCarousel welcome="Welcome to flyup.uk" />
    </div>
  );
};

export default Home;
