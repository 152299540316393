const activityData = [
  { id: 1, title: "All" },
  { id: 2, title: "Kitesurf" },
  { id: 3, title: "Landboard" },
  { id: 4, title: "Paraglide" },
  { id: 5, title: "Paramotor" },
  { id: 6, title: "Plane" },
  { id: 7, title: "Sailing" },
  { id: 8, title: "Skydive" },
  { id: 9, title: "Snowboard" },
  { id: 10, title: "Wakeboard" },
  { id: 11, title: "Wingfoil" },
  { id: 12, title: "Windsurf" },
];

export default activityData;
